import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"

import style from "../components/page/page.module.css"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className={style.content}>
      <h1>Pagina niet gevonden</h1>
      <p>deze pagina bestaat niet.</p>
      <Link to="/">Home</Link>
    </div>
  </Layout>
)

export default NotFoundPage
